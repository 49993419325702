<template>
  <div>
    <!-- <p>用户产品列表</p> -->
    <el-table v-loading="loading" class="list-style" :data="tableData" border style="width: 100%">
      <el-table-column
        fixed
        type="index"
        label="序号"
        :index="initIndex"
        align="center"
        width="100"
      />
      <el-table-column align="center" prop="productId" label="id" />
      <el-table-column align="center" prop="subject" label="名称" />
      <el-table-column align="center" prop="image" label="缩略图">
        <template slot-scope="scope">
          <img
            style="width: 100px; height: 100px"
            :src="scope.row.mainImage"
            alt="暂无图片"
            srcset=""
          >
        </template>
      </el-table-column>>
      <!-- <el-table-column align="center" prop="wholesalePrice" label="批发价">
        <template slot-scope="scope">
          <span>{{ scope.row.wholesalePrice }} 元</span>
        </template>
      </el-table-column> -->
      <el-table-column align="center" prop="wholesalePrice" label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="details(scope.row)">
            查看
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="fenye"
      background
      :current-page="page.current"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="page.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="page.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <el-dialog
      top="10vh"
      style="text-align: left;"
      title="商品详情"
      :visible.sync="dialogVisible"
      width="70%"
      :before-close="handleClose"
    >
      <div style="max-height:65vh;overflow-y: scroll;">
        <div class="title-text">
          标题
        </div>
        <div>
          {{ detailsObj.subject }}
        </div>
        <div class="line" />
        <div class="title-text">
          图片
        </div>
        <div class="imageout">
          <img v-for="(item,index) in imageList" :key="index" class="images" :src="item" alt="暂无图片" srcset="">
        </div>
        <div class="line" />
        <div class="title-text">
          规格
        </div>
        <alibabaTable :sku-list-data="detailsObj.skuList" />
        <div class="line" />
        <div class="title-text">
          商品介绍
        </div>
        <div class="details">
          <div v-html="detailsObj.description" />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getUserProductList, getUserProductDetails } from '@/api/register'
import Pagination from './pagination.js'
import alibabaTable from './alibabaTable'
export default {
  name: 'UserProductList',
  components: {
    alibabaTable
  },
  mixins: [Pagination],
  data() {
    return {
      tableData: [],
      detailsObj: {},
      imageList: []
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      this.loading = true
      getUserProductList(this.page)
        .then((result) => {
          if (result.code === 0) {
            this.loading = false
            this.tableData = result.data.records
            this.page.total = result.data.total
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    details(row) {
      this.dialogVisible = true
      getUserProductDetails(row.id).then(res => {
        console.log(res, '上架的详情')
        this.detailsObj = res.data
        const a = this.detailsObj.image.substring(1, this.detailsObj.image.length - 2)
        this.imageList = a.split(',')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .list-style{
    margin: 10px 0;
  }
  .fenye{
    display: flex;
    justify-content: end;
  }
  .line{
    margin: 10px 0;
  }

  .imageout{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-flow: wrap;
  }
  .images{
    width: 200px;
    height: 200px;
  }

  .details{
    height: 500px;
    overflow-y: scroll;
  }
  .title-text{
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 6px;
  }
</style>
