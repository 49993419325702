
export default {
  data() {
    return {
      loading: false,
      page: { size: 10, current: 1, total: 0 },
      dialogVisible: false
    }
  },
  methods: {
    initIndex(index) {
      return (this.page.current - 1) * this.page.size + index + 1
    },
    handleSizeChange(size) {
      this.page.size = size
      this.page.current = 1
      this.getList()
    },
    handleCurrentChange(current) {
      this.page.current = current
      this.getList()
    },
    handleClose(done) {
      done()
      // this.$confirm('确认关闭？')
      //   .then(_ => {
      //     done()
      //   })
      //   .catch(_ => {});
    }
  }

}
