<template>
  <!-- sku 表格 -->
  <el-table :data="skuListData" size="mini" border>
    <el-table-column
      fixed
      type="index"
      label="序号"
      align="center"
      width="100"
    />
    <el-table-column
      v-for="(item, index) in specsList"
      :key="item"
      :prop="item"
      :label="item"
      align="center"
    >
      <template slot-scope="scope">
        {{ scope.row.attributes[index].attributeValue }}
      </template>
    </el-table-column>
    <el-table-column align="center" prop="wholesalePrice" label="批发价" />
    <el-table-column align="center" prop="amountOnSale" label="销量" />
  </el-table>
</template>

<script>
export default {
  props: {
    skuListData: {
      type: Array,
      default: () => { [] }
    }
  },
  computed: {
    specsList() {
      // 筛选出几个规格值名称
      const list = []
      if (this.skuListData && this.skuListData.length > 0) {
        this.skuListData[0].attributes.map((item) => {
          // 只取规格名称
          list.push(item.attributeName)
        })
      }
      return list
    }
  },
  methods: {
  }
}
</script>

<style>

</style>
