<template>
  <div style="min-height: calc(100vh - 93px); background-color: #eaedf1; padding: 1.25vw">
    <div class="top">
      <div class="title">
        已上架的商品
      </div>
      <!-- <div class="search">
        <el-select v-model="value" size="small" placeholder="请选择" style="margin-right:20px">
          <el-option
            v-for="item in alibabaCategoryList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <a-input-search placeholder="搜素商品" @search="onSearch" />
      </div> -->
    </div>
    <userProductList />
  </div>
</template>

<script>
import userProductList from '../selectionlistcomponent/userProductList'
import Pagination from '../selectionlistcomponent/pagination'
export default {
  components: {
    userProductList
  },
  mixins: [Pagination],
  data() {
    return {
      value: '1',
      alibabaCategoryList: [{
        label: '全部',
        value: '1'
      }]
    }
  },
  methods: {
    onSearch() {}
  }
}
</script>

<style lang="scss" scoped>
.top{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}
.title {
  text-align: left;
  font-size: 28px;
  font-weight: 700;
  color: #363d4d;
}
.search{
  display: flex;
  align-items: center;
}

</style>
